<!--begin::Page bg image-->
<style>
@import "/mod.css";

body {
  background-image: url("/assets/media/auth/bg10.jpeg");
}

[data-bs-theme="dark"] body {
  background-image: url("/assets/media/auth/bg10-dark.jpeg");
}
</style>
<!--end::Page bg image-->

<template>
  <div class="d-flex flex-column flex-root app-root mt-5" id="kt_app_root">
    <!--begin::Page-->
    <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
      <!--begin::Header-->
      <div
        id="kt_app_header"
        class="app-header"
        data-kt-sticky="true"
        data-kt-sticky-activate="{default: false, lg: true}"
        data-kt-sticky-name="app-header-sticky"
        data-kt-sticky-offset="{default: false, lg: '300px'}"
      >
        <!--begin::Header container-->
        <div
          class="app-container container-fluid d-flex align-items-stretch justify-content-between"
          id="kt_app_header_container"
        >
          <!--begin::Header mobile toggle-->
          <div
            class="d-flex align-items-center d-none ms-n3 me-2"
            title="Show sidebar menu"
          >
            <div
              class="btn btn-icon btn-color-gray-600 btn-active-color-primary w-35px h-35px"
              id="kt_app_header_menu_toggle"
            >
              <i class="ki-outline ki-abstract-14 fs-2"></i>
            </div>
          </div>
          <!--end::Header mobile toggle-->
          <!--begin::Logo-->
          <div
            class="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-8 mb-5"
          >
            <a href="index.html mb-5">
              <img
                alt="Logo"
                src="/assets/media/logos/tni-logo.png"
                class="h-45px d-lg-none"
              />
              <img
                alt="Logo"
                src="/assets/media/logos/tni-logo.png"
                class="h-60px d-none d-lg-inline app-sidebar-logo-default theme-light-show"
              />
            </a>
            <span class="menu-title fs-1 fw-bold d-lg-none m-5"
              >Tentara Nasional Indonesia</span
            >
          </div>
          <!--end::Logo-->
          <!--begin::Header wrapper-->
          <div
            class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
            id="kt_app_header_wrapper"
          >
            <!--begin::Menu wrapper-->
            <div
              class="app-header-menu app-header-mobile-drawer show align-items-stretch"
            >
              <!--begin::Menu-->
              <div
                class="menu menu-rounded menu-active-bg menu-state-primary menu-column menu-lg-row menu-title-gray-700 menu-icon-gray-500 menu-arrow-gray-500 menu-bullet-gray-500 my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
                id="kt_app_header_menu"
                data-kt-menu="true"
              >
                <!--begin:Menu item-->
                <div
                  class="menu-item here show menu-here-bg menu-lg-down-accordion me-0 me-lg-2"
                >
                  <!--begin:Menu link-->
                  <span class="menu-title fs-1 fw-bold hide-mobile mt-3"
                    >Tentara Nasional Indonesia</span
                  >
                  <!--end:Menu link-->
                </div>
                <!--end:Menu item-->
              </div>
              <!--end::Menu-->
            </div>
            <!--end::Menu wrapper-->
            <!--begin::Navbar-->
            <div class="app-navbar flex-shrink-0">
              <!--begin::Search-->
              <div class="d-flex align-items-center align-items-stretch">
                <div
                  id="kt_header_search"
                  class="d-flex align-items-center"
                  data-kt-search="true"
                >
                  <!-- <router-link
                    :to="{ name: 'check-kta' }"
                    type="button"
                    class="btn btn-success"
                  >
                    <span class="indicator-label">Periksa KTA</span>
                  </router-link> -->
                </div>
              </div>
              <div
                id="kt_header_search"
                class="d-flex align-items-center d-none"
              >
                <a
                  href="login.html"
                  type="button"
                  class="btn btn-light-success"
                >
                  <span class="indicator-label">Masuk</span>
                </a>
              </div>
              <!--end::Search-->
              <!--begin::Header menu toggle-->
              <!--end::Header menu toggle-->
            </div>
            <!--end::Navbar-->
          </div>
          <!--end::Header wrapper-->
        </div>
        <!--end::Header container-->
      </div>
      <!--end::Header-->
      <!--begin::Wrapper-->
      <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-6">
          <!--begin::Toolbar container-->
          <div
            id="kt_app_toolbar_container"
            class="app-container container-fluid d-flex align-items-start pb-5"
          >
            <!--begin::Toolbar container-->
            <div class="d-flex flex-column flex-row-fluid">
              <!--begin::Toolbar wrapper-->
              <div class="d-flex align-items-center pt-1">
                <!--begin::Breadcrumb-->
                <ul
                  class="breadcrumb breadcrumb-separatorless fw-semibold d-none"
                >
                  <!--begin::Item-->
                  <li class="breadcrumb-item text-white fw-bold lh-1">
                    <a
                      href="../../demo30/dist/index.html"
                      class="text-white text-hover-warning"
                    >
                      <i class="ki-outline ki-home text-white fs-3"></i>
                    </a>
                  </li>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <li class="breadcrumb-item">
                    <i class="ki-outline ki-right fs-4 text-white mx-n1"></i>
                  </li>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <li class="breadcrumb-item text-white fw-bold lh-1">
                    <a href="index.html" class="text-white text-hover-warning"
                      >Dashboards</a
                    >
                  </li>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <li class="breadcrumb-item">
                    <i class="ki-outline ki-right fs-4 text-white mx-n1"></i>
                  </li>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <li class="breadcrumb-item text-white fw-bold lh-1">
                    Periksa KTA
                  </li>
                  <!--end::Item-->
                </ul>
                <!--end::Breadcrumb-->
              </div>
              <!--end::Toolbar wrapper=-->
            </div>
            <!--end::Toolbar container=-->
          </div>
          <!--end::Toolbar container-->
        </div>
        <!--end::Toolbar-->
        <!--begin::Wrapper container-->
        <div class="app-container container-fluid">
          <!--begin::Main-->
          <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
            <!--begin::Content wrapper-->
            <div class="d-flex flex-column flex-column-fluid">
              <!--begin::Content-->
              <div
                id="kt_app_content"
                class="app-content dashboard py-0 p-0 mb-md-10"
              >
                <!--begin::Row-->
                <div class="row gx-5 gx-xl-10">
                  <!--begin::Col-->
                  <div class="col-xxl-12 mb-5 mb-xl-10">
                    <router-view />
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Row-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Content wrapper-->
          </div>
          <!--end:::Main-->
        </div>
        <!--end::Wrapper container-->
      </div>

      <!--begin::Footer-->
      <div
        id="kt_app_footer"
        class="app-footer d-flex flex-column flex-md-row align-items-center flex-center flex-md-stack py-2 py-lg-4 bg-tni p-10 mt-10"
      >
        <!--begin::Copyright-->
        <div class="text-dark order-2 order-md-1">
          <span class="text-white fw-semibold me-1"
            >2024© PUSPEN Markas Besar Tentara Nasional Indonesia</span
          >
        </div>
        <!--end::Copyright-->
        <!--begin::Menu-->
        <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          <li class="menu-item">
            <a
              href="https://twitter.com/Puspen_TNI"
              target="_blank"
              class="btn btn-icon btn-light-success me-1 mb-1"
            >
              <i class="ki-duotone ki-twitter fs-1">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </a>
          </li>
          <li class="menu-item">
            <a
              href="https://www.facebook.com/Pusat-Penerangan-TNI-350005585069284/"
              target="_blank"
              class="btn btn-icon btn-light-success me-1 mb-1"
            >
              <i class="ki-duotone ki-facebook fs-1">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </a>
          </li>
          <li class="menu-item">
            <a
              href="https://www.instagram.com/puspentni/"
              target="_blank"
              class="btn btn-icon btn-light-success me-1 mb-1"
            >
              <i class="ki-duotone ki-instagram fs-1">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </a>
          </li>
          <li class="menu-item">
            <a
              href="https://www.youtube.com/user/PuspenTNI"
              target="_blank"
              class="btn btn-icon btn-light-success me-1 mb-1"
            >
              <i class="ki-duotone ki-youtube fs-1">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </a>
          </li>
          <li class="menu-item">
            <a
              href="https://www.tiktok.com/@puspentni"
              target="_blank"
              class="btn btn-icon btn-light-success me-1 mb-1"
            >
              <i class="ki-duotone ki-tiktok fs-1">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </a>
          </li>
        </ul>
        <!--end::Menu-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Wrapper-->
  </div>
</template>

<style>
@media screen and (max-width: 800px) {
  .hide-mobile {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
</style>
<script>
export default {};
</script>
